import { render, staticRenderFns } from "./ButtonComment.vue?vue&type=template&id=7f20e58c&scoped=true&"
import script from "./ButtonComment.vue?vue&type=script&lang=js&"
export * from "./ButtonComment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f20e58c",
  null
  
)

export default component.exports